import React from "react"
import Layout from "../components/UI/Layout"
import Head from "../components/Head"
import QuickContact from "../components/Sections/QuickContact"
import Button from "../components/UI/Button"
import {
  OrangeSpan,
  GrayBorder,
  Section,
  Container,
  DoubleGrid,
} from "../globalStyle"
import Companies from "../components/Sections/Companies"
import useDigitalTransformationQuery from "../helpers/queries/digitalTransformationQuery"
import TrustedUs from "../components/Sections/TrustedUs"
import PageTitle from "../components/Elements/PageTitle"
import digitalTransformationChainData from "./../data/components/digitalTransformChain"
import ChainDevelopment from "../components/Sections/ChainDevelopment"
import ClutchTitle from "../components/Sections/ClutchTitle"
import Project from "../components/UI/Project"
import useDigitalTransformationProjectQuery from "../helpers/queries/digitalTransformationProjectQuery"
import digitalTransformProjectData from "../data/components/digitalTransformProject"
import BecomeDigital from "../components/Sections/BecomeDigital"
import DigitalTransformationFeatures from "../components/Sections/DigitalTransformationFeatures"

const DigitalTransformation = () => {
  const digitalTransformationData = useDigitalTransformationQuery()
  const digitalTransformationImageData = useDigitalTransformationProjectQuery()
  return (
    <Layout>
      <Head
        description="Transform your business into the company of the future using cutting-edge technologies"
        pageTitle="Digital transformation for business"
        keywords="software, oakfusion"
      />
      <div style={{ marginBottom: "150px" }}></div>
      <Section>
        <PageTitle title="Digital Transformation" />
      </Section>
      <GrayBorder />
      <ClutchTitle
        title="Transform your business"
        coloredTitle="into the company of the future"
        text="Our software brings digitalization to the industry. Using Industry 4.0 technologies enables us to create systems that optimize workflows, manages big amounts of data and give systems new dimension of efficiency.
Our offer is directed to the manufacturers, magazines, logistic centers – everyone that proceeds great amount of goods and data that can be automated.
We have created software systems using IoT and Cloud, done integrations with SAP and other enterprise applications and many more."
        Button={() => <Button slug="/contact">Order free consultation</Button>}
      />
      <BecomeDigital />
      <DigitalTransformationFeatures />
      <Section>
        <Container>
          <DoubleGrid>
            {digitalTransformProjectData.map(p => (
              <Project
                key={p.id}
                isInService={true}
                data={p}
                image={
                  digitalTransformationImageData.allFile.edges[p.id - 1].node
                    .childImageSharp.gatsbyImageData
                }
              />
            ))}
          </DoubleGrid>
        </Container>
      </Section>
      <ChainDevelopment chainElements={digitalTransformationChainData} />
      <TrustedUs
        text="Oakfusion’s professional software development skills, their deep understanding for business driven requirements and their proactive working style have been an important success factor for the launch of our new product within given time frame and budget."
        name="Patrick Gerstenecker, Senior Product Manager"
        who="Immobilienscout24.de"
      />
      <Companies companies={digitalTransformationData} />
      <QuickContact
        title={
          <>
            Got other <OrangeSpan>ideas?</OrangeSpan>
          </>
        }
        text={"Make it happen"}
        button={<Button slug="/contact">Contact</Button>}
      />
    </Layout>
  )
}

export default DigitalTransformation
