import cloudIcon from "@iconify-icons/et/cloud"
import syncLine from "@iconify-icons/clarity/sync-line"
import dataVis1 from "@iconify-icons/carbon/data-vis-1"
import brainCircuit20Regular from "@iconify-icons/fluent/brain-circuit-20-regular"

const digitalTransformationData = [
  {
    id: 1,
    image: cloudIcon,
    name: "Cloud - Digitalize customer experience",
    text:
      "Make buying process easier by providing customers with 3D visualistaions .",
  },
  {
    id: 2,
    image: syncLine,
    name: "System Integration - Cloud and Big Data",
    text:
      "Gather huge amounts of data in order to analyze them in cloud and improve the processes. If something is measurable, it can be improved.",
  },
  {
    id: 3,
    image: dataVis1,
    name: "Big Data - Artificial Intelligence",
    text:
      "Use AI to optimize your processes in order to achieve maxium efficiency.",
  },
  {
    id: 4,
    image: brainCircuit20Regular,
    name: "Software - Automate",
    text:
      "Automate industrial workflow to safe time and speed up the production. We use technologies of industry 4.0 to connect machines together using IoT.",
  },
]

export default digitalTransformationData
