import React from "react"
import { Container, ListItemWrapper } from "./style"
import {
  H2Title,
  H3Title,
  Section,
  P,
  M,
  AnimatedBackground,
  TripleGrid,
} from "../../../globalStyle"
import { useTheme } from "styled-components"
import ListItem from "../../UI/ListItem"
import becomeDigitalItemData from "../../../data/components/becomeDigitalListItem"

const BecomeDigital = () => {
  const theme = useTheme()
  return (
    <Section>
      <AnimatedBackground />
      <Container>
        <H2Title
          style={{ marginLeft: "15px", marginRight: "15px" }}
          color={theme.colors.white}
          center={true}
        >
          Become digital with Oakfusion
        </H2Title>
        <TripleGrid>
          <M>
            <H3Title>Industry 4.0</H3Title>
            <P color={theme.colors.white}>
              Introducing cutting-edge technologies and software solutions to
              big companies. Industry 4.0 is all about independent machines
              working together and improving processes.{" "}
            </P>
            <ListItemWrapper>
              {becomeDigitalItemData[0].map(i => (
                <ListItem key={i.id} icon={i.icon}>
                  {i.text}
                </ListItem>
              ))}
            </ListItemWrapper>
          </M>
          <M>
            <H3Title>Smart Devices</H3Title>
            <P color={theme.colors.white}>
              Using smart devices to measure sport activities, air pollution, in
              gamification and wherever it is applicable – check out our work –
              click4fit and jumpilot.{" "}
            </P>
            <ListItemWrapper>
              {becomeDigitalItemData[1].map(i => (
                <ListItem key={i.id} icon={i.icon}>
                  {i.text}
                </ListItem>
              ))}
            </ListItemWrapper>
          </M>
          <M>
            <H3Title>Digital Business</H3Title>
            <P color={theme.colors.white}>
              Digitalizing business means imporving customers experience and
              making work easier and more efficient. We will show you how to do
              it.
            </P>
            <ListItemWrapper>
              {becomeDigitalItemData[2].map(i => (
                <ListItem key={i.id} icon={i.icon}>
                  {i.text}
                </ListItem>
              ))}
            </ListItemWrapper>
          </M>
        </TripleGrid>
      </Container>
    </Section>
  )
}

export default BecomeDigital
