import airPolution from "../../data/images/products/Air-Quality-App-1-300x300.jpg"

const digitalTransformProjectData = [
  {
    id: 1,
    title: "Air Quality App",
    image: airPolution,
    slug: "/products/air-quality-app",
    description_1:
      "It all started with the idea of measuring the air pollution in Wroclaw. Since we already had an experience in IoT from previous projects, the challenge was to choose appropriate hardware and decide what exactly should be measured. That’s how we came up with our own device and simple but efficient architecture.",
    description_2:
      "The measuring device sends data to the server via MQTT Broker. Server sends data to the database in order to save and store it, and the same data is taken from the server to be displayed on the web in real-time. Data can be also displayed on the integrated mobile application.",
    backgroundColor: "transparent",
  },
]

export default digitalTransformProjectData
