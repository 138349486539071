import styled from "styled-components"

export const FeatureCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
`

export const ImageWrapper = styled.div`
  margin-bottom: 20px;
  & > svg {
    width: 60px;
    height: 60px;
    color: ${props => props.theme.colors.orange};
  }
`
