import { useStaticQuery, graphql } from "gatsby"

const useDigitalTransformationProjectQuery = () => {
  const digitalTransformationProjectQuery = useStaticQuery(graphql`
    query digitalTransformationProjectQuery {
      allFile(
        filter: {
          extension: { eq: "jpg" }
          name: { in: ["Air-Quality-App-1-300x300"] }
        }
      ) {
        edges {
          node {
            id
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `)
  return digitalTransformationProjectQuery
}

export default useDigitalTransformationProjectQuery
