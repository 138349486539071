import React from "react"
import {
  Section,
  H2Title,
  QuadrupleGrid,
  M,
  OrangeSpan,
} from "../../../globalStyle"
import { Container } from "./style"
import FeatureCard from "./FeatureCard"
import digitalTransformationData from "../../../data/components/digitalTransfrormationFeatures"

const DigitalTransformationFeatures = () => {
  return (
    <Section>
      <Container>
        <M>
          <H2Title center={true}>
            What <OrangeSpan>we do</OrangeSpan>
          </H2Title>
        </M>
        <QuadrupleGrid>
          {digitalTransformationData.map(c => (
            <FeatureCard
              key={c.id}
              image={c.image}
              text={c.text}
              title={c.name}
            ></FeatureCard>
          ))}
        </QuadrupleGrid>
      </Container>
    </Section>
  )
}

export default DigitalTransformationFeatures
