import React from "react"
import { Icon } from "@iconify/react"
import { FeatureCardWrapper, ImageWrapper } from "./style"
import { P, H3Title } from "../../../../globalStyle"
import IFeatureCard from "./types"

const FeatureCard = (props: IFeatureCard) => {
  const { image, text, title } = props
  return (
    <FeatureCardWrapper>
      <ImageWrapper>
        <Icon icon={image}></Icon>
      </ImageWrapper>
      <H3Title isDarkBlue={true} center={true}>
        {title}
      </H3Title>
      <P center={true}>{text}</P>
    </FeatureCardWrapper>
  )
}

export default FeatureCard
