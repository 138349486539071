import styled from "styled-components"

export const Container = styled.div`
  width: 1470px;
  @media (max-width: 1470px) {
    width: 100%;
  }
`
export const TextWrapper = styled.div`
  margin-top: 0;
  padding: 0 15px 0 15px;
`
