import checkLg from "@iconify-icons/bi/check-lg"

const becomeDigitalItemData = [
  [
    {
      id: 1,
      text: "factories",
      icon: checkLg,
    },
    {
      id: 2,
      text: "logistic centers",
      icon: checkLg,
    },
    {
      id: 3,
      text: "warehouses",
      icon: checkLg,
    },
  ],
  [
    {
      id: 1,
      text: "continuous improvement on the art of programing,",
      icon: checkLg,
    },
    {
      id: 2,
      text: "oak solid work",
      icon: checkLg,
    },
    {
      id: 3,
      text: "adaptability & customize",
      icon: checkLg,
    },
  ],
  [
    {
      id: 1,
      text: "sport training",
      icon: checkLg,
    },
    {
      id: 2,
      text: "smart houses",
      icon: checkLg,
    },
    {
      id: 3,
      text: "digital measurements",
      icon: checkLg,
    },
    {
      id: 4,
      text: "smart cards and keys",
      icon: checkLg,
    },
  ],
  [
    {
      id: 1,
      text: "startups",
      icon: checkLg,
    },
    {
      id: 2,
      text: "web platforms",
      icon: checkLg,
    },
    {
      id: 3,
      text: "mobile apps",
      icon: checkLg,
    },
    {
      id: 4,
      text: "real estate agencies",
      icon: checkLg,
    },
    {
      id: 5,
      text: "fin-tech",
      icon: checkLg,
    },
    {
      id: 6,
      text: "insurance",
      icon: checkLg,
    },
  ],
]

export default becomeDigitalItemData
