import styled from "styled-components"

export const Container = styled.div`
  width: 1470px;
  margin-bottom: 100px;
  z-index: 400;
  & > div:nth-child(1) {
    margin-bottom: 100px;
  }

  @media (max-width: 1470px) {
    width: 100%;
  }
`
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px;
  @media (max-width: 760px) {
    padding: 30px 15px 30px 15px;
  }
`
export const ContactWrapper = styled.div`
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  margin-top: 30px;
  @media (max-width: 760px) {
    flex-direction: column;
  }
`

export const ListItemWrapper = styled.div`
  & > p {
    color: ${props => props.theme.colors.white};
  }
`
